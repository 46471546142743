import type { WorkOrderPriority } from '@/graphql/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { Flex, FlexProps, Text } from '@chakra-ui/react';
import type { FC } from 'react';

type WorkOrderPriorityType = {
  title?: string;
  color: string;
  value: WorkOrderPriority;
};

export const workOrderNonePriority: WorkOrderPriorityType = {
  value: 'none',
  color: 'neutral.400',
};

export const workOrderLowPriority: WorkOrderPriorityType = {
  value: 'low',
  title: '低',
  color: 'success',
};

export const workOrderMediumPriority: WorkOrderPriorityType = {
  value: 'medium',
  title: '中',
  color: 'warning.500',
};

export const workOrderHighPriority: WorkOrderPriorityType = {
  value: 'high',
  title: '高',
  color: 'error.500',
};

export const workOrderPriorityLabelOptions = [
  workOrderNonePriority,
  workOrderLowPriority,
  workOrderMediumPriority,
  workOrderHighPriority,
];

export const workOrderPriorityLabelMap = new Map<WorkOrderPriority, WorkOrderPriorityType>([
  ['none', workOrderNonePriority],
  ['low', workOrderLowPriority],
  ['medium', workOrderMediumPriority],
  ['high', workOrderHighPriority],
]);

type WorkOrderPriorityLabelProps = {
  priority: WorkOrderPriority;
} & FlexProps;

const WorkOrderPriorityLabel: FC<WorkOrderPriorityLabelProps> = (
  props: WorkOrderPriorityLabelProps
) => {
  const { priority, ...flexProps } = props;
  const { t, i18n } = useTranslation();

  if (priority === 'none') return <></>;

  const priorityLabel = workOrderPriorityLabelMap.get(priority);

  if (priorityLabel === undefined) throw Error('priorityLabel undefined');

  return (
    <Flex
      minW='24px'
      h='24px'
      borderRadius='5'
      justifyContent='center'
      alignItems='center'
      border='1px'
      borderColor={priorityLabel.color}
      background='white'
      px={i18n.language === 'en' ? 2 : 0}
      {...flexProps}
    >
      <Text color={priorityLabel.color} fontSize='sm'>
        {t(`priority.${priorityLabel.value}`)}
      </Text>
    </Flex>
  );
};

export default WorkOrderPriorityLabel;
